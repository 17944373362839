/* FIXES
--------------------------------------------------------------------------------------------*/

// TOPTASK
.ac_toptasks_item_icon svg {
    fill: $brand-primary;
    stroke: $brand-secondary;
}

.ac_toptasks_item_image-container {
    background: $brand-secondary;
}

.ac_toptasks_item_container {
    height: 120px;
}

.ac_toptasks_item_image {
    display: block;
    width: 114px;
    height: 114px; 
    margin: 3px;
}

.ac_toptasks_item_content_title {
    padding-left: 10px;
}

//FORMS

@media (min-width: 640px) {
    .gform-theme--framework .left_label .gform-field-label:where(:not([class*=gform-field-label--type-]):not(.gfield_header_item):not(.ginput_quantity_label)), .gform-theme--framework .right_label .gform-field-label:where(:not([class*=gform-field-label--type-]):not(.gfield_header_item):not(.ginput_quantity_label)) {
        font-weight: 600;
    }
}

@media (min-width: 640px) {
    .gform-theme--foundation:where(:not(.gform-editor--compact)) .left_label .gform-field-label:where(:not([class*=gform-field-label--type-]):not(.gfield_header_item):not(.ginput_quantity_label)), .gform-theme--foundation:where(:not(.gform-editor--compact)) .right_label .gform-field-label:where(:not([class*=gform-field-label--type-]):not(.gfield_header_item):not(.ginput_quantity_label)) {
        flex-direction: row !important;
    }
}

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

[data-s-type="hero"] .ac_hero_container {
    height: 55vh !important;
}

//HEADERS
[data-s-type="hero-alt"] {
    background-color: #223588;
}

.home .ac_menu-2_bar_bottom {
    display: none;
}
.ac_menu-2_logo_img {
    display: none;
}

.ac_menu-2_logo_img {
    display:block;
}
[data-m-type="menu-2"] .ac_menu-2_main_content ul.menu.dropdown a {
    margin-left:20px;
    margin-right: 0px;
}

.mobile_logo_img {
    display: none;
    
}

.ac_menu-2_main_content {
    margin-top:-10px;
}

.ac_menu-2_bar_bottom_container {
    max-width: 1200px;
}

.ac_menu-2_bar_bottom {
    background-color: #ffffff;
    a {
        text-transform: uppercase;
        color: $brand-secondary !important;
        font-size: 15px !important;
    }
}

.ac_menu-2_main {
    max-width: 1200px !important;
}

.menu-top-container {
    a{
        color:$brand-primary !important;
        border:2px solid $brand-primary;
        border-radius: 5px;
        text-transform: uppercase;
        padding:10px 10px 10px 10px;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: right;
    }
}

.ac_menu-2_logo_container{
        img {
        margin-top:-25px !important;
        max-width: 250% !important;
    }
}

.ac_menu-2_logo_img {
    width: 320px !important;
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 140px;
    margin-top:-5px !important;
}

.ac_service_nav_sticky.is-stuck {
    width: 140px;
    margin-top:-5px !important;
}

.ac_menu-2_bar_top {
    background: none;
    a {
        color:$brand-secondary !important;
    }
}

@media screen and (max-width: 1100px){
    [data-s-type="hero"] .ac_hero_container {
        height: 60vh !important;
    }
    
}

[data-s-type="hero"] .ac_hero_slide_content_text {
    color: $brand-black;
    font-size: 20px;
}

[data-s-type="hero"] .ac_hero_slide_content_inner {
    justify-content: flex-start;
}

.ac_menu-2_cta_link {
    font-size: 20px !important;
}

[data-m-type="menu-2"] {
    a {
        color:$brand-black;
        font-weight: 600;
        font-size: 13px;
    }
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:85px;
    min-height: 85px;
}

[data-s-type="hero-alt"] .ac_hero_alt_slide_inner{
    margin:10px auto !important;
}

#breadcrumbs {
    display: none;
}

// MENU MOBILE
@media screen and (max-width: 49.9375em){
    .ac_menu-2_sticky{
        display: block;
    }

    [data-s-type="hero"] .ac_hero_container {
        height: 50vh !important;
    }

    [data-toggle-menu-search] {
        display: none;
    }
    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}

h2, h3, h4 {
    color: $brand-primary;
    padding-bottom:10px;
}

// GENERAL
.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-primary;
    &.button {
        color: $brand-white;
        text-decoration: none;
    }
}

.button {
    background: linear-gradient(
        -90deg, #223588, #223588);
}

body {
    color:$brand-black;
}

//listbuttons
@media screen and ( min-width: 1200px ){
    [data-s-type="sticky"] {
        position: fixed;
        top: 120px;
        width: 100%;
        z-index: 999;
    }
}

//NEWS

[data-s-id="home-news"] .ac_item_image-container {
    display: none;
}
[data-s-type="news"] .ac_item_content_title {
    color: $brand-black;
}


.hero-image-wijngaarden {
    margin-left: 75px;
    margin-top: 100px;
}

.hero-alt-image-wijngaarden {
        max-height: 85px;
        margin-top: 5px;
}

.ac_hero_alt_slide_content_text {
    text-align: left !important;
}

[data-s-type="hero-alt"] .ac_hero_alt_slide_inner {
    padding: 0 18px;
    max-width: 1200px;
    margin: 16px auto !important;
}
// BRANDS

.v_ghost_brand_primary{
    margin-left:20px;
}

.ac_brands_item_container {
    border: none !important;
}

.ac_brands_item {
    border: none !important;
}

// SERVICES
[data-s-id="home-services"] {
    padding-bottom: 0px;
 
}

.ac_item_container {
    border: 2px solid $brand-primary;
    border-radius: 10px;
}


[data-s-type="services"] {
    .ac_heading {
        position: relative;
        z-index: 99;
        font-weight: bold;
    }
}

[data-s-type="wijngaarden-footer"] {
    height:230px;
    .ac_backdrop_image {
        background-repeat: no-repeat;
        background-position: top !important;
    }
    
}


//FOOTER
[data-f-type="footer-1"] {
    display: none;
}



//CTA
.ac_cta_column {
    p {
        font-size: 18px;
        font-weight: 600;
    }
}

// IMAGES OVERLAY
[data-s-type="image-gallery_full-screen"] .slick-next svg, [data-s-type="image-gallery_full-screen"] .slick-prev svg {
    fill: $brand-primary;
}

li::marker {
    color:$brand-primary;
}

.ac_content_1-1 {
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and ( max-width: 640px ){
    [data-s-type="quotes"] .ac_item{

        .ac_item_content_copy{
            padding: 10px 40px;
        }

        .ac_item_content_title {
            color: white;
        }

        .ac_item_content_title_quote-left{
            left: -3em;
        }

        .ac_item_content_title_quote-right{
            right: -3em;
        }

        .ac_item_content_copy-below{
            display: none;
        }
    }
    .hero-image-wijngaarden {
        margin-left:0px !important;
    }

}
@media screen and ( max-width: 800px ){

    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4) {
        margin-left: -12px;
    }

    [data-s-type="hero-alt"] .ac_hero_alt_container {
        min-height: 70px;
        height: 70px;
    }
    [data-s-type="hero-alt"] .ac_hero_alt_slide_inner {
        margin: -2px auto !important;
    }

}


@media screen and ( max-width: 500px ){
    [data-s-type="quotes"] .ac_item{
        padding: 1.5em 20px;
    }
}

span.anchor {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;
}

.ac_content_2-3 + .ac_content_1-3 {
    img {
        float:right;
    }
}
.ac_content_1-3 {
    img {
        float:left;
    }
}
.ac_content_2-3 + .ac_content_1-3 {
    img {
        float:right !important;
    }
}